<template lang="pug">
.visit-item
  .name {{ visit.location.name }}
    icon.ml-2(icon='check' v-if='visit.completed')
    span.distance.ml-3(v-if='showDistance && visit.distance') vence {{ visit.distance }}
  .dates Entre {{ visit.start | datetime }} y {{ visit.end | datetime }}
</template>

<script>
// import { subDays, formatDistance } from 'date-fns'
import dateDistanceStrict from '@/filters/dateDistanceStrict.filter'
export default {
  props: {
    visit: { type: Object },
    showDistance: { type: Boolean }
  },
  computed: {
    distance () {
      if (!this.visit) return null
      return dateDistanceStrict(this.visit.end, true)
    }
  }
}
</script>

<style lang="scss" scoped>
.visit-item {
  margin-bottom: 10px;
}

.dates,
.distance {
  font-size: 0.7em;
}
</style>
