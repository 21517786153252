<template lang="pug">
div
  .container.mt-3
    register-visit(:location='currentLocation' v-if='currentLocation' @submitted='visitSubmitted' @cancelled='visitCancelled')
    scan(@scanned='scanned' v-else)
  b-tabs(v-model='activeTab' v-if='visits.length')
    b-tab-item(v-if='pastVisits.length')
      template(#header)
        span
         | Faltan
         b-tag.ml-1(rounded type='is-danger') {{ pastVisits.length }}
      visit-item(:visit='visit' :key='visit.uid' v-for='visit in pastVisits')
    b-tab-item(label="Pendiente")
      visit-item(:visit='visit' v-for='visit in pendingVisits' :key='visit.uid' show-distance)
      .pt-3
        b Más adelante
      visit-item(:visit='visit' v-for='visit in futureVisits' :key='visit.uid' show-distance)
    b-tab-item(label="Completo")
      visit-item(:visit='visit' v-for='visit in completedVisits' :key='visit.uid')
  .container.text-center.mt-5(v-else)
    i No hay programaciones para sitios con etiquetas. Puede escanear una etiqueta.
</template>

<script>
import locationsService from '@/services/locations.service'
import visitsService from '@/services/visits.service'
import toastService from '@/services/toast.service'
import { inPointRegisterLocation } from '@/router/routes'
import RegisterVisit from '@/components/inpoint/RegisterVisit'
import Scan from '@/components/inpoint/Scan'
import VisitItem from '@/components/inpoint/VisitItem'
import dateDistanceStrict from '@/filters/dateDistanceStrict.filter'

let refreshInterval = null

export default {
  components: { RegisterVisit, Scan, VisitItem },
  data () {
    return {
      currentLocation: null,
      visits: [],
      locations: null,
      activeTab: 0,
      visitsObject: {
        pastVisits: [],
        pendingVisits: [],
        futureVisits: [],
        completedVisits: []
      }
    }
  },
  async created () {
    const [locationsResult, visitsResult] = await Promise.all([
      locationsService.getAll({ hasCode: true }),
      visitsService.schedule()
    ])
    if (locationsResult && visitsResult) {
      this.locations = locationsResult
      this.visits = visitsResult.map(visit => {
        visit.location = locationsResult.find(location => location.id === visit.locationId)
        return visit
      })
      this.updateVisitLists()
      refreshInterval = setInterval(() => {
        this.updateVisitLists()
      }, 10000)
    }
  },
  destroyed () {
    if (refreshInterval) {
      clearInterval(refreshInterval)
    }
  },
  computed: {
    pastVisits () {
      return this.visitsObject.pastVisits
    },
    pendingVisits () {
      return this.visitsObject.pendingVisits
    },
    futureVisits () {
      return this.visitsObject.futureVisits
    },
    completedVisits () {
      return this.visitsObject.completedVisits
    }
  },
  methods: {
    updateVisitLists () {
      this.visits.forEach(visit => {
        this.$set(visit, 'distance', dateDistanceStrict(visit.end, true))
      })
      this.visitsObject = {
        pastVisits: this.visits.filter(visit => !visit.completed && new Date(visit.end) <= new Date()),
        pendingVisits: this.visits.filter(visit => !visit.completed && new Date() >= new Date(visit.start) && new Date(visit.end) > new Date()),
        futureVisits: this.visits.filter(visit => !visit.completed && new Date() < new Date(visit.start) && new Date(visit.end) > new Date()),
        completedVisits: this.visits.filter(visit => visit.completed)
      }
    },
    async scanned (code) {
      const location = this.locations.find(location => location.code === code)
      if (location) {
        this.currentLocation = location
      } else {
        toastService.show(`La etiqueta ${code} no existe`)
        this.$router.push({ name: inPointRegisterLocation.name, query: { code } })
      }
    },
    async visitSubmitted (currentVisit) {
      const possibleVisit = this.visits.find(visit =>
        visit.locationId === currentVisit.locationId &&
      !visit.completed && new Date(visit.start) <= new Date() &&
      new Date() < new Date(visit.end)
      )
      if (possibleVisit) {
        possibleVisit.completed = true
      }
      this.currentLocation = null
    },
    visitCancelled () {
      this.currentLocation = null
    }
  }
}
</script>

<style lang="scss" scoped>
.event-item {
  margin-bottom: 10px;
}
</style>
