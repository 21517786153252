<template lang="pug">
form.mb-5(@submit.prevent='submit')
  p.text-center.mb-2 Registrar visita para
    b.ml-1 {{ currentVisit.location.name }}
  div(v-for='event in currentVisit.securityEvents' :key='event._id')
    event.event-item(@remove='removeEvent(event)' @event-changed='updateEvent(event, $event)' :loading='event.loading')
  .flex.mb-2(v-if='!isSaving')
    button.button.is-fullwidth(@click='addNewEvent' type='button') {{ currentVisit.securityEvents.length ? 'Agregar otro evento' : 'Agregar un evento' }}
  .flex
    b-button(type='is-danger' :loading='isSaving' expanded @click='cancelScan') Cancelar
    b-button.ml-2(type='is-primary' native-type='submit' :loading='isSaving' expanded) Guardar visita
</template>

<script>
import toastService from '@/services/toast.service'
import visitsService from '@/services/visits.service'
import filesService from '@/services/files.service'
import Event from '@/components/inpoint/Event'
import { fileFolders } from '@/constants/enums'

export default {
  components: { Event },
  props: {
    location: { type: Object, required: true }
  },
  data () {
    return {
      isSaving: false,
      currentVisit: null
    }
  },
  created () {
    this.currentVisit = {
      location: this.location,
      securityEvents: []
    }
  },
  methods: {
    addNewEvent () {
      this.currentVisit.securityEvents.push({
        _id: new Date().getTime(),
        loading: false
      })
    },
    removeEvent (event) {
      this.currentVisit.securityEvents = this.currentVisit.securityEvents.filter(e => e._id !== event._id)
    },
    updateEvent (currentEvent, newEvent) {
      Object.assign(currentEvent, newEvent)
    },
    cancelScan () {
      toastService.confirm('¿Desea cancelar?', () => {
        this.$emit('cancelled')
      })
    },
    async submit () {
      this.isSaving = true
      let failed = false
      for (let i = 0; i < this.currentVisit.securityEvents.length; i++) {
        const event = this.currentVisit.securityEvents[i]
        event.loading = true
        for (let y = 0; y < event.files.length; y++) {
          const eventFile = event.files[y]
          const result = await filesService.uploadToBucket(eventFile.file, fileFolders.Inpoint)
          if (result) {
            eventFile.dbPath = result
          } else {
            toastService.error('Falló la carga del archivo')
            failed = true
            break
          }
        }
        event.photos = event.files.map(file => file.dbPath).join()
        event.loading = false
        if (failed) break
      }
      if (failed) {
        this.isSaving = false
        return
      }

      const { id: locationId } = this.currentVisit.location
      const { securityEvents } = this.currentVisit
      const visit = {
        locationId,
        securityEvents
      }
      const result = await visitsService.save(visit)
      if (result) {
        toastService.show('Visita guardada')
        this.$emit('submitted', visit)
      }
      this.isSaving = false
    }
  }
}
</script>

<style lang="scss" scoped>
.scan {
  padding: 10px;
  text-align: center;
}
</style>
