<template lang="pug">
.event-card
  .event-card-content
    //- h3.event-title Imágenes
    button.card-delete-button(type='button' @click='remove')
      icon(icon='times')
    input.input-file(ref='camera' type='file' accept='image/*' capture='camera' @change='inputChanged($event)')
    input.input-file(ref='gallery' type='file' accept='image/png, image/jpeg' @change='inputChanged($event)')
    .event-images
      .event-image(v-for='file in event.files' :style="{ 'background-image': `url('${file.preview}')` }")
      .event-image.add-image(@click='pickImage("camera")')
        icon(icon='camera')
      .event-image.add-image(@click='pickImage("gallery")')
        icon(icon='images')
    .severity
      span.mr-2 Severidad:
      button.button.is-small(type='button' :class="event.severity === severity.None ? 'is-success' : ''" @click='setSeverity(severity.None)') Ninguna
      button.button.is-small(type='button' :class="event.severity === severity.Low ? 'is-info' : ''" @click='setSeverity(severity.Low)') Baja
      button.button.is-small(type='button' :class="event.severity === severity.Med ? 'is-warning' : ''" @click='setSeverity(severity.Med)') Media
      button.button.is-small(type='button' :class="event.severity === severity.High ? 'is-danger' : ''" @click='setSeverity(severity.High)') Alta
    div
      span.mr-2 Comentario:
      a(@click='addComment' v-if='event.comments') {{ event.comments }}
      a(@click='addComment' v-else)
        i Click para cargar
    b-progress.mt-1(size='is-small' v-if='loading')
</template>

<script>
import toastService from '@/services/toast.service'
import { securityEventSeverity } from '@/constants/enums'

export default {
  props: {
    loading: { type: Boolean }
  },
  data () {
    return {
      severity: securityEventSeverity,
      event: {
        severity: securityEventSeverity.None,
        files: [],
        comments: ''
      }
    }
  },
  methods: {
    inputChanged ($event) {
      if ($event.target.files.length) {
        this.uploadFile($event.target.files[0])
      }
    },
    uploadFile (file) {
      if (!this.event.files) {
        this.event.files = []
      }
      const preview = URL.createObjectURL(file)
      this.event.files.push({
        file,
        preview
      })
      this.eventChanged()
    },
    setSeverity (severity) {
      this.event.severity = severity
      this.eventChanged()
    },
    remove () {
      this.$emit('remove')
    },
    pickImage (input) {
      this.$refs[input].click()
    },
    async addComment () {
      toastService.prompt('Agregar un comentario', '', null, comment => {
        this.event.comments = comment
        this.eventChanged()
      })
    },
    eventChanged () {
      this.$emit('event-changed', this.event)
    }
  }
}
</script>

<style lang="scss" scoped>
.event-card {
  padding: 10px;
  border: solid 1px #dadada;
  position: relative;
}

.card-delete-button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  border: none;
  background: none;
}

.input-file {
  display: none;
}

.event-title {
  font-size: 1em;
  color: gray;
  margin-bottom: 5px;
}

.severity {
  margin-bottom: 5px;
}

.event-images {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.event-image {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px 2px 0;
  border: solid 2px #dadada;
  overflow: hidden;
    background-size: cover;

  img {
    width: 100%;
  }
}

.add-image {
  border-style: dashed;
}
</style>
