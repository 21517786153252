<template lang="pug">
.scan
  button.button(@click='scan' v-if='!reading && !error') Iniciar el scanner
  div(v-if='reading') Acerce el teléfono a una etiqueta para escanearla
  div(v-if='error') Ha ocurrido un error con el lector NFC. Revise que el dispositivo si tenga lector NFC, que haya otorgado los permisos a esta aplicación e intente de nuevo.
</template>

<script>
import { mapActions } from 'vuex'
import nfcModule from '@/store/modules/nfc.module'

export default {
  created () {
    nfcModule.subscribeReading(tagNumber => {
      this.$emit('scanned', tagNumber)
    })
  },
  computed: {
    reading () {
      return this.$store.state.nfc.reading
    },
    error () {
      return this.$store.state.nfc.error
    }
  },
  methods: {
    ...mapActions({
      startNfc: 'nfc/start'
    }),
    async scan () {
      this.startNfc()
      // try {
      //   // this.$emit('scanned', 123456)
      //   const ndef = new window.NDEFReader()
      //   await ndef.scan()
      //   this.reading = true

      //   ndef.addEventListener('readingerror', () => {
      //     this.log.push('Argh! Cannot read data from the NFC tag. Try another one?')
      //   })

      //   ndef.addEventListener('reading', (result) => {
      //     const { serialNumber } = result
      //     const tagNumber = parseInt('0x' + serialNumber.replaceAll(':', ''))
      //     this.$emit('scanned', tagNumber)
      //   })
      // } catch (error) {
      //   this.error = true
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.scan {
  padding: 10px;
  text-align: center;
}
</style>
